.swiper-slide-shadow.swiper-slide-shadow-creative {
  border-radius: var(--radius) !important;
}
.swiper-pagination {
  pointer-events: none;
}
.swiper-button-prev,
.swiper-button-next {
  padding: 1rem;
  width: auto;
  height: auto;
  transform: translateY(-50%);
}
